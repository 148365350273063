<template>
  <!-- Тестовая страница для мониторинга исполнение мпк -->
  <div class="container-page">
    <MonitoringContainer
      containerTitle="Мониторинг исполнения поручений МПК"
      :viewAllFoiv="viewAllFoiv"
      :totalDataByFoiv="totalDataByFoiv"
      firstLabel="Страна"
      v-model:firstFilterModel="filterByFoiv.countryId"
      :firstSelectList="computedCountryList"
      firstPlaceholder="Выберите страну"
      secondLabel="Мероприятия МПК"
      v-model:secondFilterModel="filterByFoiv.mpkEventId"
      :secondSelectList="mpkEventListByCountry"
      secondPlaceholder="Выберите мероприятия МПК"
      :dataMonitoring="dataMonitoringByFoiv"
      :dataMonitoringAll="dataMonitoringByFoivAll"
      :dataMonitoringInitLength="dataMonitoringByFoivInitLength"
      :nn="nnByFoiv"
      @setLimits="setLimitsFoiv"
    >
      <InfoCountList :info-count-list="getInfoCountList" />
    </MonitoringContainer>

    <MonitoringContainer
      containerTitle="Мониторинг поручений по всем странам (сводный)"
      firstLabel="ФОИВ"
      v-model:firstFilterModel="filterByCountry.organizationId"
      :firstSelectList="organizationList"
      firstPlaceholder="Выберите ФОИВ"
      secondLabel="Мероприятия МПК"
      v-model:secondFilterModel="filterByCountry.mpkEventId"
      :secondSelectList="mpkEventList"
      secondPlaceholder="Выберите мероприятия МПК"
      :dataMonitoring="dataMonitoringByCountry"
      :dataMonitoringAll="dataMonitoringByCountryAll"
      :dataMonitoringInitLength="dataMonitoringByCountryInitLength"
      :nn="nnByCountry"
      @setLimits="setLimitsCountry"
    >
    </MonitoringContainer>
  </div>
</template>

<script>
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import Utils from '@/common/utils';

  import InfoCountList from '@/components/InfoCountList';

  import API_ORG from '../../admin/api/organization';
  import API from '../api/assignment';
  import MonitoringContainer from '../components/assignmentMonitoring/MonitoringContainer';
  import { SET_LIMIT_MOD } from '../utils/constants';

  const ALL_OPTION_ID = 0;

  export default {
    name: 'AssignmentMonitoring',
    components: {
      InfoCountList,
      MonitoringContainer,
    },
    computed: {
      getInfoCountList() {
        const notExecuteCount = this.totalDataByFoiv?.notExecute + this.totalDataByFoiv?.overdue;
        return [
          {
            title: 'Количество поручений у всех ФОИВ',
            count: this.totalData.totalCount,
          },
          { title: 'Выполнено', count: this.totalDataByFoiv.done },
          {
            title: 'В стадии выполнения',
            count: this.totalDataByFoiv.inWork,
          },
          {
            title: 'Не выполнено',
            count: Number.isNaN(notExecuteCount) ? '' : notExecuteCount,
          },
        ];
      },
      // для правки отображения выбранного значения в `@vueform/multiselect`
      computedCountryList() {
        return [...this.countryList];
      },
    },
    watch: {
      'filterByFoiv.countryId'() {
        const countryId = this.parseParamId(this.filterByFoiv.countryId);
        this.$router.replace({
          query: countryId ? { countryId } : null,
        });
        this.filterByFoiv.mpkEventId = ALL_OPTION_ID;
        this.loadMpkEventsByCountry();
        this.loadPageByFoiv();
      },
      'filterByFoiv.statusIds'() {
        this.loadPageByFoiv();
      },
      'filterByFoiv.mpkEventId'() {
        this.loadPageByFoiv();
      },
      'filterByCountry.organizationId'() {
        this.loadPageByCountry();
      },
      'filterByCountry.statusIds'() {
        this.loadPageByCountry();
      },
      'filterByCountry.mpkEventId'() {
        this.loadPageByCountry();
      },
    },
    mixins: [security],
    data() {
      return {
        viewAllFoiv: false,

        filterByFoiv: {
          countryId: this.$route?.query?.countryId ?? ALL_OPTION_ID,
          mpkEventId: ALL_OPTION_ID,
          statusIds: [],
        },
        nnByFoiv: 0,
        filterByCountry: {
          organizationId: ALL_OPTION_ID,
          statusIds: [],
          mpkEventId: ALL_OPTION_ID,
        },
        nnByCountry: 0,
        statusList: [],
        organizationList: [],
        countryList: [{ id: ALL_OPTION_ID, text: 'Все', selected: true }],
        mpkEventList: [],
        mpkEventListByCountry: [],
        totalData: {
          totalCount: 0,
        },
        totalDataByFoiv: {},
        totalDataByCountry: {},
        dataMonitoringByFoiv: [],
        dataMonitoringByFoivAll: [],
        dataMonitoringByFoivLength: 0,
        dataMonitoringByFoivInitLength: 20,

        dataMonitoringByCountry: [],
        dataMonitoringByCountryAll: [],
        dataMonitoringByCountryLength: 0,
        dataMonitoringByCountryInitLength: 20,
      };
    },
    created() {
      this.checkPermissionAndRedirect(Constants.Permissions.MpkAssignmentAccess);
      this.checkPermission(Constants.Permissions.MpkAssignmentViewAllFoiv).then((response) => {
        this.viewAllFoiv = response.data;

        if (this.viewAllFoiv) {
          this.loadOrganization();
        }
      });

      Utils.loadSelectOptions('countryList', this.countryList);
      Utils.searchNsi('mpkAssignmentStatusList', {}).then((response) => {
        if (response.data && response.data.length) {
          response.data.forEach((i) => this.statusList.push({ value: i.id, label: i.text }));
        }
      });
      this.loadMpkEvent();
      this.loadMpkEventsByCountry();
      this.loadPage();
    },
    methods: {
      // преобразуем query параметр со значением `0` в `null`, чтобы он не отображался в url
      parseParamId(param) {
        const parsedParam = parseInt(param);
        if (parsedParam === ALL_OPTION_ID || Number.isNaN(parsedParam)) {
          return null;
        }
        return parsedParam;
      },
      async getMpkEvents(countryId) {
        const events = await API.getEventForList({ countryId })
          .then((response) => response.data)
          .catch(() => []);
        return [{ id: ALL_OPTION_ID, text: 'Все', selected: true }].concat(events);
      },
      async loadMpkEventsByCountry() {
        const countryId = this.parseParamId(this.filterByFoiv.countryId);
        this.mpkEventListByCountry = await this.getMpkEvents(countryId);
      },
      async loadMpkEvent() {
        this.mpkEventList = await this.getMpkEvents();
      },
      loadOrganization() {
        API_ORG.searchShortFormat({
          pageNumber: 1,
          pageSize: 0,
          typeIds: [Constants.organizationType.foiv],
          isActive: true,
        }).then((response) => {
          this.organizationList = [{ id: 0, text: 'Все', selected: true }];
          if (response.data && response.data.items.length) {
            response.data.items.forEach((i) => this.organizationList.push({ id: i.id, text: i.name }));
          }
        });
      },
      loadPage() {
        API.getTotal({ countryId: 0 }).then((response) => {
          this.totalData = response.data;
        });

        this.loadPageByCountry();
        this.loadPageByFoiv();
      },

      loadPageByFoiv() {
        const params = Object.assign({}, this.filterByFoiv);
        API.getTotalByFoiv(params).then((response) => {
          this.dataMonitoringByFoiv = [];
          this.totalDataByFoiv = {};
          if (response.data && response.data.length > 0) {
            this.totalDataByFoiv = response.data[0];
            response.data.sort((a, b) => b.totalCount - a.totalCount);
            response.data.slice(1).forEach((el) => {
              this.dataMonitoringByFoiv.push({
                name: el.name,
                data: [el.done, el.notExecute + el.overdue, el.inWork],
              });
            });
            this.nnByFoiv++;
          }

          this.dataMonitoringByFoivAll = JSON.parse(JSON.stringify(this.dataMonitoringByFoiv));
          this.setLimitsFoiv();
        });
      },
      loadPageByCountry() {
        var params = Object.assign({}, this.filterByCountry);
        API.getTotalByCountry(params).then((response) => {
          this.dataMonitoringByCountry = [];
          this.totalDataByCountry = {};
          if (response.data && response.data.length > 0) {
            this.totalDataByCountry = response.data[0];
            response.data.sort((a, b) => b.totalCount - a.totalCount);
            response.data.slice(1).forEach((el) => {
              this.dataMonitoringByCountry.push({
                name: el.name,
                data: [el.done, el.notExecute + el.overdue, el.inWork],
              });
            });
            this.nnByCountry++;
          }

          this.dataMonitoringByCountryAll = JSON.parse(JSON.stringify(this.dataMonitoringByCountry));
          this.setLimitsCountry();
        });
      },

      setLimitsFoiv(mod) {
        if (mod) {
          if (mod === SET_LIMIT_MOD.ALL) {
            return (this.dataMonitoringByFoiv = this.dataMonitoringByFoivAll);
          }

          if (mod === SET_LIMIT_MOD.ADD) {
            this.dataMonitoringByFoiv = this.dataMonitoringByFoivAll;
            this.dataMonitoringByFoivLength = this.dataMonitoringByFoivLength + 10;
            return (this.dataMonitoringByFoiv = this.dataMonitoringByFoiv.slice(0, this.dataMonitoringByFoivLength));
          }
        }

        this.dataMonitoringByFoivLength = 20;

        return (this.dataMonitoringByFoiv = this.dataMonitoringByFoiv.slice(0, this.dataMonitoringByFoivInitLength));
      },
      setLimitsCountry(mod) {
        if (mod) {
          if (mod === SET_LIMIT_MOD.ALL) {
            return (this.dataMonitoringByCountry = this.dataMonitoringByCountryAll);
          }

          if (mod === SET_LIMIT_MOD.ADD) {
            this.dataMonitoringByCountry = this.dataMonitoringByCountryAll;
            this.dataMonitoringByCountryLength = this.dataMonitoringByCountryLength + 10;
            return (this.dataMonitoringByCountry = this.dataMonitoringByCountry.slice(
              0,
              this.dataMonitoringByCountryLength,
            ));
          }
        }

        this.dataMonitoringByCountryLength = 20;

        return (this.dataMonitoringByCountry = this.dataMonitoringByCountry.slice(
          0,
          this.dataMonitoringByCountryInitLength,
        ));
      },
    },
  };
</script>
